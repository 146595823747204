import { Map, TileLayer, Marker, Popup, FeatureGroup, Icon } from 'leaflet'
import { MarkerClusterGroup } from 'leaflet.markercluster'
import AnimatedSearchBox from 'leaflet.animatedsearchbox/src/Leaflet.AnimatedSearchBox.js'
import SidePanel from 'leaflet.sidepanel'

// Import main custom CSS to global CSS space
import './main.css'

// Import leaflet CSS into global CSS space
import 'leaflet/dist/leaflet.css'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import 'leaflet.animatedsearchbox/src/Leaflet.AnimatedSearchBox.css'
import 'leaflet.sidepanel/dist/leaflet-sidepanel.css'

import concertsAll from './concerts.json'
const concerts = concertsAll.filter((c) => !c['skip'] && c['position'])

function main() {
  const map = new Map('map', {
    minZoom: 0,
    maxZoom: 12,
    closePopupOnClick: false,
  })
  map.attributionControl.setPrefix(
    'Website by <a href="https://dbruhn.de">Dominik Bruhn</a> | Map Library by <a href="https://leafletjs.com/">Leaflet</a>'
  )
  new TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: 19,
    attribution:
      'Map &copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>',
  }).addTo(map)

  // Super crude way to find out how big we can make the popups
  const popupWidth = Math.min(
    660,
    window.innerWidth - 60,
    (window.innerHeight / 9) * 16 - 220
  )
  console.log(
    'popupWidth',
    popupWidth,
    (popupWidth / 16) * 9,
    window.innerHeight,
    (window.innerHeight / 9) * 16
  )

  const icon = new Icon.Default()
  const iconSearch = new Icon(
    Object.assign({}, Icon.Default.prototype.options, {
      iconUrl: 'build/images/marker-icon-search.png',
      iconRetinaUrl: 'build/images/marker-icon-search-2x.png',
      shadowUrl: 'build/images/marker-shadow.png',
    })
  )
  console.log('Icon', icon, iconSearch)

  const markers = new MarkerClusterGroup({
    maxClusterRadius: 15,
    attribution: 'All Videos &copy; <a href="https://cercle.io">Cercle</a>',
  })
  for (const concert of concerts) {
    if (!concert.position || concert.position.length != 2) {
      continue
    }

    const marker = new Marker(concert.position, { icon: icon })
    markers.addLayer(marker)
    concert.marker = marker

    const popup = new Popup({ minWidth: popupWidth }).setContent(
      '<b>' +
        concert.title +
        '</b>' +
        (concert.date ? '<br><i>Recorded ' + concert.date + '</i><br/>' : '') +
        '<div class="popup-video" style="background-image: url(\'https://img.youtube.com/vi/' +
        concert.id +
        '/mqdefault.jpg\')">' +
        '<iframe type="text/html" src="https://www.youtube.com/embed/' +
        concert.id +
        '?autoplay=1" frameborder="0" allow="fullscreen;" />' +
        '</div>'
    )
    marker.bindPopup(popup)
  }

  map.addLayer(markers)
  map.fitBounds(markers.getBounds())

  const searchbox = new AnimatedSearchBox({
    position: 'topright',
    expand: 'left',
    iconPath: 'build/images/search_icon.png',
  }).addTo(map)

  function search() {
    const v = searchbox.getValue()

    if (v && v != '') {
      console.log('Search', v)
      const searchResults = concerts.filter((c) =>
        c.title.toLowerCase().includes(v.toLowerCase())
      )
      console.log('Search Results', searchResults)

      // Remove Color from all other markers
      concerts.forEach((c) => c.marker.setIcon(icon))

      if (searchResults.length > 0) {
        const searchResultMarkers = searchResults.map((c) => c.marker)

        // Zoom the Search Result Markers into View
        const searchResultGroup = new FeatureGroup(searchResultMarkers)
        map.fitBounds(searchResultGroup.getBounds())

        // Change the search results markers to a different icon
        searchResultMarkers.forEach((m) => {
          m.setIcon(iconSearch)
        })

        // If only one result, open popup for this marker
        if (searchResults.length == 1) {
          searchResultMarkers[0].openPopup()
        }
      }
    }

    // No matter if there was a result, hide search box and clear value
    searchbox.hide()
    searchbox.setValue('')
  }

  searchbox.onButton('click', search)
  searchbox.onInput('keyup', (e) => {
    if (e.keyCode == 13) {
      search()
    }
  })

  new SidePanel('sidepanel', {
    panelPosition: 'left',
    startTab: 'tab-1',
  }).addTo(map)
}

main()
